import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { API_BASE_URL } from "..";
import { IResponseObject } from "../interfaces";
import { FaCheck } from "react-icons/fa6";
import { convertDate } from "../utils";
import Loading from "./Loading";
import { ClipLoader, DotLoader } from "react-spinners";
import { apiFetch } from "../service/useApi";

export default function TrackingModal({
  setShowTracking,
  showTracking,
  orderId,
}: {
  showTracking: boolean;
  setShowTracking: any;
  orderId: string;
}) {
  let [isOpen, setIsOpen] = useState(true);
  const [loading, setLoading] = useState(true);
  const [orderSteps, setOrderSteps] = useState<any>();
  const [currentStatus, setCurrentStatus] = useState<any>();
  function closeModal() {
    setShowTracking(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const getOrderTrack = async () => {
    setLoading(true);
    const { isLoaded, data } = await apiFetch(
      `/api/Order/TrackOrder?orderId=${orderId}`,
      "GET"
    );
    if (isLoaded && data.statusCode == 200) {
      setLoading(false);

      setOrderSteps(data.data);
      const currentStatus = data.data.statuses.filter(
        (stage: any) => stage.isCurrentStatus
      );
      setCurrentStatus(currentStatus[0]);
      console.log({ currentStatus });
    }

    // try {
    //   const res = await fetch(url, {
    //     method: "GET",
    //     headers: {
    //       "content-type": "application/json",
    //       Authorization: `Bearer ${accessToken}`,
    //     },
    //   });
    //   const stages: IResponseObject = await res.json();
    //   if (stages.statusCode == 200) {
    //     setLoading(false);
    //     // const newStages = stages.data.filter(
    //     //   (d: any) => d.value > 1 && d.value !== 400 && d.value < 900
    //     // );
    //     // console.log(newStages);
    //     // setOrderSteps(newStages);
    //     setOrderSteps(stages.data);
    //     console.log(stages);
    //     const currentStatus = stages.data.statuses.filter(
    //       (stage: any) => stage.isCurrentStatus
    //     );
    //     setCurrentStatus(currentStatus[0]);
    //     console.log({ currentStatus });
    //   }
    // } catch (error) {
    //   setLoading(false);
    //   console.log(error);
    // }
  };

  const getOrderStatus = async () => {
    const userId = localStorage.getItem("userId") as unknown as string;
    const storeId = localStorage.getItem("storeFrontId") as unknown as string;
    const orderId = "08FF802A-0202-48C6-4A7A-08DC6C561D4C";
    const accessToken = localStorage.getItem(
      "accessToken"
    ) as unknown as string;
    console.log(userId);
    const url = new URL(
      API_BASE_URL +
        `/api/Order/GetOrderStatus?orderId=${orderId}&storefrontId=${storeId}`
    );
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const status: IResponseObject = await res.json();
      if (status.statusCode == 200) {
        setLoading(false);
        console.log(status);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOrderTrack();
  }, []);

  if (loading) {
    <ClipLoader
      color={"red"}
      loading={loading}
      size={20}
      aria-label="Loading Spinner"
      data-testid="loader"
    />;
  }

  return (
    <>
      <Transition appear show={showTracking} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full h-[272px] transform overflow-hidden rounded bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-bold leading-6 text-gray-900 border-b h-14 flex justify-center items-center relative"
                  >
                    Order Tracking
                  </Dialog.Title>
                  {loading ? (
                    <div className="w-full h-full flex items-center justify-center">
                      <DotLoader
                        color={"#F89521"}
                        loading={true}
                        // cssOverride={override}
                        size={100}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    </div>
                  ) : (
                    <>
                      <div className="mt-2 flex justify-between">
                        <div>
                          <p className="text-sm text-gray-500">Order ID.</p>
                          <p className="font-medium">
                            {orderSteps && orderSteps.orderNo}
                          </p>
                        </div>
                        <div>
                          <div className="py-2 w-36 flex justify-center items-center rounded bg-[#F3FFD9] text-[#8ED100] text-xs">
                            {currentStatus && currentStatus.status}
                          </div>
                          <p className="text-sm mt-1">
                            {currentStatus &&
                              convertDate(
                                new Date(currentStatus.statusUpdateDate)
                              )}
                          </p>
                        </div>
                      </div>

                      <div className="mt-4 flex justify-around items-center">
                        {orderSteps
                          ? orderSteps.statuses.map(
                              (step: any, index: number) => (
                                <>
                                  <div className="flex flex-col items-center">
                                    <div
                                      className={`w-8 h-8 rounded-full ${
                                        step.isCurrentStatus || step.isCompleted
                                          ? "bg-brandGreen-100"
                                          : "bg-brandBlack-600"
                                      } flex justify-center items-center text-white`}
                                    >
                                      {step.isCompleted ? (
                                        <FaCheck color="#ffffff" size={18} />
                                      ) : (
                                        index + 1
                                      )}
                                    </div>
                                    <p
                                      className={`font-medium ${
                                        step.isCurrentStatus || step.isCompleted
                                          ? "text-brandGreen-100"
                                          : "text-brandBlack-600"
                                      }`}
                                    >
                                      {step.status}
                                    </p>
                                    <p className="text-sm">
                                      {step.statusUpdateDate
                                        ? convertDate(
                                            new Date(step.statusUpdateDate)
                                          )
                                        : ""}
                                    </p>
                                  </div>
                                  {index < 6 && (
                                    <span className="w-20 border"></span>
                                  )}
                                </>
                              )
                            )
                          : ""}
                      </div>
                    </>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
