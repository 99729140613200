import React, { useContext, useEffect, useState } from "react";
import CustomizationLayout from "../components/customization/CustomizationLayout";
import { FaMinus, FaPlus } from "react-icons/fa";
import { FiX } from "react-icons/fi";
import LayoutTab from "../components/customization/LayoutTab";
import TextTab from "../components/customization/TextTab";
import PhotoTab from "../components/customization/PhotoTab";
import FabricCanvas from "../components/FabricCanvas";
import { API_BASE_URL } from "..";
import { useParams } from "react-router-dom";
import CustomizationContext from "../CustomizationContext";

export const enum LayoutView {
  layout,
  text,
  photo,
  sticker,
  savedDesigns,
  null,
}
const Customize = () => {
  const [view, setView] = useState<LayoutView>(LayoutView.null);
  const [product, setProduct] = useState<any>();

  const { setPaperSize } = useContext(CustomizationContext);

  const hideView = () => {
    setView(LayoutView.null);
  };

  const layoutViewFn = () => {
    switch (view) {
      case LayoutView.layout:
        return <LayoutTab hideView={hideView} />;
      case LayoutView.text:
        return <TextTab hideView={hideView} />;
      case LayoutView.photo:
        return <PhotoTab hideView={hideView} />;
      default:
        break;
    }
  };
  const tenantId = localStorage.getItem("tenantId") as unknown as string;
  const userId = localStorage.getItem("userId") as unknown as string;
  const storeFrontId = localStorage.getItem(
    "storeFrontId"
  ) as unknown as string;
  let { id } = useParams();

  const getProduct = async () => {
    const url = new URL(
      API_BASE_URL +
        `/api/Product/GetProductBySlug?Slug=${id}&StoreConfig.TenantId=${tenantId}&StoreConfig.StoreFrontId=${storeFrontId}&StoreConfig.UserId=${userId}`
    );
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST, GET, PUT",
          "Access-Control-Allow-Headers": "Content-Type",
        },
      });
      const product = await res.json();
      console.log(product);
      if (product) {
        console.log(product.data);
        setProduct(product.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProduct();
  }, []);

  return (
    <CustomizationLayout
      view={view}
      setView={setView}
      amount={product?.unitPrice}
    >
      <div className="flex h-full">
        {layoutViewFn()}
        <div className="flex flex-col w-full">
          <div className="flex-1 bg-brandBlue-100 flex items-center justify-center">
            <FabricCanvas />
          </div>
          <div className="w-full py-8 border-t flex justify-center items-center gap-x-24">
            <div>
              <p className="font-medium text-xl mb-2">Pages</p>
              <div className="flex gap-x-6">
                <div className="flex flex-col items-center">
                  <div className="h-[110px] w-[77px] border border-brandBlack-200"></div>
                  <p className="text-sm text-brandBlack-200">Front</p>
                </div>
                <div className="flex">
                  <div className="flex flex-col items-center">
                    <div className="h-[110px] w-[77px] border border-brandBlack-200"></div>
                    <p className="text-sm text-brandBlack-200">Inside Left</p>
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="h-[110px] w-[77px] border border-brandBlack-200"></div>
                    <p className="text-sm text-brandBlack-200">Inside Right</p>
                  </div>
                </div>
                <div className="flex flex-col items-center">
                  <div className="h-[110px] w-[77px] border border-brandBlack-200"></div>
                  <p className="text-sm text-brandBlack-200">Back</p>
                </div>
              </div>
            </div>
            <div>
              <p className="font-medium text-xl mb-2">Card Sizes</p>
              <div className="flex gap-x-4">
                <div
                  className="px-4 py-2 rounded-lg flex flex-col gap-y-2 items-center bg-brandOrange-200 text-white cursor-pointer"
                  onClick={() => setPaperSize({ width: 500, height: 300 })}
                >
                  <p className="font-medium">Small (A6)</p>
                  <p className="text-sm">₦5,000</p>
                </div>
                <div
                  className="px-4 py-2 rounded-lg flex flex-col gap-y-2 items-center border border-brandBlack-200 cursor-pointer"
                  onClick={() => setPaperSize({ width: 600, height: 400 })}
                >
                  <p className="font-medium">Standard (A5)</p>
                  <p className="text-sm">₦5,000</p>
                </div>
                <div
                  className="px-4 py-2 rounded-lg flex flex-col gap-y-2 items-center border border-brandBlack-200 cursor-pointer"
                  onClick={() => setPaperSize({ width: 800, height: 600 })}
                >
                  <p className="font-medium">Large (A4)</p>
                  <p className="text-sm">₦5,000</p>
                </div>
                <div
                  className="px-4 py-2 rounded-lg flex flex-col gap-y-2 items-center border border-brandBlack-200 cursor-pointer"
                  onClick={() => setPaperSize({ width: 1000, height: 800 })}
                >
                  <p className="font-medium">Giant (A3)</p>
                  <p className="text-sm">₦5,000</p>
                </div>
              </div>
              <div>
                <p className="font-medium text-lg">Quantity</p>
                <div className="mt-1 flex gap-x-2 items-center">
                  <span className="h-[18px] w-[18px] rounded-full flex justify-center items-center bg-brandOrange-200 text-white">
                    <FaMinus color="#fff" size={10} />
                  </span>
                  <div className="w-[30px] h-[33px] rounded flex justify-center items-center border border-brandBlack-200 font-medium text-sm">
                    1
                  </div>
                  <span className="h-[18px] w-[18px] rounded-full flex justify-center items-center bg-brandOrange-200 text-white">
                    <FaPlus color="#fff" size={10} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CustomizationLayout>
  );
};

export default Customize;
