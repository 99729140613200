import React, { useEffect, useState } from "react";
import Template from "../components/Template";
import Navbar from "../components/Navbar";
import OrderCard from "../components/OrderCard";
import { API_BASE_URL } from "..";
import { Link, useNavigate } from "react-router-dom";
import { IResponseObject } from "../interfaces";
import Loading from "../components/Loading";
import EmptyState from "../assets/Empty-order.png";
import { apiFetch } from "../service/useApi";

const History = () => {
  enum OrderRequestStatus {
    OpenOrderRequestStatus = "OPEN",
    DeliveryOrderRequestStatus = "CLOSED",
  }
  const [orderType, setOrderType] = useState("open");
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState(OrderRequestStatus.OpenOrderRequestStatus);

  const [order, setOrder] = useState<any[]>([]);

  // 

  const getHistory = async () => {
    setLoading(true);
    try {
        const { data, isLoaded } = await apiFetch(
          "/api/Order/GetCusotmerOrderHistory",
          "GET"
        );
        if (isLoaded && data.statusCode == 200) {
          setLoading(false)
          setOrder(data.data);
        }
      } catch (error) {
        console.log(error)
      }
    }

  useEffect(() => {
    getHistory();
  }, [orderType]);

  const navigate = useNavigate();

  return (
    <Template>
      <div className="w-full grid grid-cols-6 px-20 py-10 gap-x-5">
        <div className="col-span-1 drop-shadow-lg rounded-md bg-white h-[400px]">
          <Navbar currentRoute={"history"} />
        </div>
        <div className="col-span-5 drop-shadow-lg rounded-md bg-white">
          <div className="w-full border-b px-6 h-14 bg-brandBlue-100 flex items-center">
            <p className="font-medium text-xl">Order History</p>
          </div>
          <div className="px-6">
            {loading ? (
              <div className=" border ">
                <Loading size={180} />
              </div>
            ) : (
              <>
                <div className="h-12 w-full flex items-center border-b mb-4 gap-x-5 font-medium text-brandBlack-400">
                  <div
                    className={` ${
                      orderType == "open"
                        ? "border-brandOrange-200 text-brandOrange-200 border-b-2"
                        : ""
                    }  h-full flex items-center px-2 cursor-pointer`}
                    onClick={() => {
                      setOrderType("open");
                      setType(OrderRequestStatus.OpenOrderRequestStatus);
                    }}
                  >
                    Open Orders
                    {orderType === "open" && order.length > 0 && (
                      <span
                        className={`"h-3 w-3 rounded-full border text-[8px] flex items-center justify-center ml-2  ${
                          orderType == "open"
                            ? "text-brandOrange-200 border-brandOrange-200"
                            : "text-brandBlack-400 border-brandBlack-400"
                        }`}
                      >
                        {order.length}
                      </span>
                    )}
                  </div>
                  <div
                    className={` ${
                      orderType == "close"
                        ? "border-brandOrange-200 text-brandOrange-200 border-b-2"
                        : ""
                    }  h-full flex items-center cursor-pointer px-2`}
                    onClick={() => {
                      setOrderType("close");
                      setType(OrderRequestStatus.DeliveryOrderRequestStatus);
                    }}
                  >
                    Closed Orders
                    {orderType === "close" && order.length > 0 && (
                      <span
                        className={`"h-3 w-3 rounded-full border text-[8px] flex items-center justify-center ml-2  ${
                          orderType == "close"
                            ? "text-brandOrange-200 border-brandOrange-200"
                            : "text-brandBlack-400 border-brandBlack-400"
                        }`}
                      >
                        {order.length}
                      </span>
                    )}
                  </div>
                </div>

                <div className="py-5 ">
                  {order.length < 1 ? (
                    <div className="w-full h-full flex flex-col justify-center items-center">
                      <img src={EmptyState} alt="" />
                      <h3 className="font-medium text-2xl mt-2">
                        You don't have any orders yet!
                      </h3>
                      <p className="">
                        When you’ve placed an order you’ll see it here.
                      </p>
                      <Link to={"/"}>
                        <button className="rounded text-white bg-brandOrange-200 h-12 w-[325px] my-3 cursor-pointer">
                          Start Shopping
                        </button>
                      </Link>
                    </div>
                  ) : (
                    order.map((singleOrder) => (
                      <OrderCard singleOrder={singleOrder} />
                    ))
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Template>
  );
};

export default History;
