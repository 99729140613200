import React, { useEffect, useState } from "react";
import Template from "../components/Template";
import Navbar from "../components/Navbar";
import majesty from "../assets/majestycard.png";
import Card from "../components/Card";
import Loading from "../components/Loading";
import { API_BASE_URL } from "..";
import { useNavigate } from "react-router-dom";
import { IResponseObject } from "../interfaces";
import ProductsCard from "../components/ProductsCard";
import { Toaster } from "sonner";
import { apiFetch } from "../service/useApi";

const Favorites = () => {
  const [favorites, setFavorites] = useState<[]>();
  const [filteredFavorites, setFilteredFavorites] = useState<any>([]);

  const navigate = useNavigate();
  useEffect(() => {
    const getFavorites = async () => {
      try {
        const { data, isLoaded } = await apiFetch(
          "/api/Customer/GetCustomerFavouriteProducts",
          "GET"
        );
        if (isLoaded && data.statusCode == 200) {
          setFavorites(data.data);
        }
      } catch (error) {
        console.log(error)
      }
    };

    getFavorites();
  }, []);

  useEffect(() => {
    const filtered = favorites?.filter((fav: any) => fav.isFavourite == true);
    setFilteredFavorites(filtered);
    console.log("favs changed");
  }, [favorites]);

  if (!favorites) {
    return <Loading />;
  }

  console.log(favorites);

  return (
    <Template>
      <Toaster richColors position="top-right" />
      <div className="w-full grid grid-cols-6 px-20 py-10 gap-x-5">
        <div className="col-span-1 drop-shadow-lg rounded-md bg-white h-[400px]">
          <Navbar currentRoute={"favorites"} />
        </div>
        <div className="col-span-5 drop-shadow-lg rounded-md bg-white">
          <div className="w-full border-b px-6 h-14 bg-brandBlue-100 flex items-center">
            <p className="font-medium text-xl">Favorites</p>
          </div>
          <div className="px-6">
            <div className="h-12 w-full flex items-center border-b mb-4">
              {favorites.length} Items
            </div>
            {favorites.length > 0 ? (
              <div className="flex flex-wrap gap-10 py-10 ">
                {filteredFavorites &&
                  filteredFavorites.map((card: any) => {
                    if (card.isFavourite) {
                      return <ProductsCard slide={card} key={card.id} />;
                    }
                  })}
              </div>
            ) : (
              <p>No favorite products.</p>
            )}
          </div>
        </div>
      </div>
    </Template>
  );
};

export default Favorites;
